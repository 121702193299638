<template>
  <v-layout justify-center>
    <table class="cost-table">
      <tbody>
        <tr row justify-space-between>
          <td>
            Sub Total
          </td>
          <td v-if="cart && cart.sub_total">
            {{ cart.sub_total.amount | currency }}
          </td>
        </tr>

        <tr v-if="cart.taxes.breakdown && cart.taxes.breakdown.gst">
          <td>
            GST/HST
          </td>

          <td>
            {{ cart.taxes.breakdown.gst | currency }}
          </td>
        </tr>

        <tr row justify-space-between v-if="cart.taxes.breakdown && cart.taxes.breakdown.pst">
          <td>
            PST/QST
          </td>

          <td>
            {{ cart.taxes.breakdown.pst | currency }}
          </td>
        </tr>

        <tr row justify-space-between>
          <td>
            Sales Taxes
          </td>

          <td v-if="cart && cart.taxes">
            {{ cart.taxes.amount | currency }}
          </td>
        </tr>

        <tr row justify-space-between v-if="cart && cart.discount && cart.discount.amount > 0">
          <td>
            Discount
          </td>

          <td>
            {{ `$${this.getDecimal(cart.discount.amount)}` }}
          </td>
        </tr>

        <tr row justify-space-between v-if="cart && cart.promo && cart.promo.amount > 0">
          <td>
            Promotion
          </td>

          <td>
            {{ `$${this.getDecimal(cart.promo.amount)}` }}
          </td>
        </tr>

        <tr
          row
          justify-space-between
          v-if="cart && cart.service_fee && cart.service_fee.amount > 0"
        >
          <td>
            Service Fee
          </td>

          <td>
            {{ `$${this.getDecimal(cart.service_fee.amount)}` }}
          </td>
        </tr>

        <tr
          row
          justify-space-between
          v-if="cart && cart.delivery_fee && cart.delivery_fee.amount > 0"
        >
          <td>
            Delivery Fee
          </td>

          <td>
            {{ `$${this.getDecimal(cart.delivery_fee.amount)}` }}
          </td>
        </tr>

        <tr row justify-space-between>
          <td>
            Total
          </td>

          <td v-if="cart && cart.total">
            {{ cart.total.amount | currency }}
          </td>
        </tr>
      </tbody>
    </table>
  </v-layout>
</template>

<script>
export default {
  props: ['state', 'order', 'cart'],
  methods: {
    getDecimal(int) {
      return int.toFixed(2);
    },
  },
};
</script>

<style scoped>
.cost-table td {
  padding: 10px 50px;
}
</style>
