<template>
  <v-container grid-list-xl>
    <v-layout row wrap>
      <v-flex xs8>
        <div class="H3-Primary-Left">Orders</div>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs6>
        <v-autocomplete
          solo
          single-line
          label="Sites"
          prepend-inner-icon="mdi-magnify"
          :items="sites"
          item-text="name"
          item-value="id"
          :value="site_id"
          @change="setSite"
          flat
          append-icon=""
        ></v-autocomplete>
      </v-flex>
      <v-flex xs6 v-if="site_id">
        <v-autocomplete
          solo
          single-line
          label="Brand"
          :items="brands"
          item-text="name"
          item-value="id"
          :value="brand_id ? brand_id : ALL_BRANDS.id"
          @change="setBrand"
          flat
        ></v-autocomplete>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12>
        <orders-list
          v-if="brand && filterDates.startDateObject"
          :key="brand_id"
          :brand="brand"
          :brandsInfo="brandsInfo"
          :filterDates="filterDates"
          @update:filterDates="updateFilterDates"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DateTime from 'luxon/src/datetime.js';
import OrdersList from './ordersList';

export default {
  components: {
    OrdersList,
  },

  props: ['site_id', 'brand_id'],

  data: () => ({
    loadingBrands: false,
    brandsInfo: [],
    filterDates: {},
    ALL_BRANDS: Object.freeze({ id: 'All', name: 'All Stations' }),
  }),

  computed: {
    ...mapState('sites', ['siteMap']),

    sites() {
      return Object.values(this.siteMap);
    },

    brands() {
      const site_id = this.site_id || (this.sites[0] && this.sites[0].id);
      if (this.siteMap[site_id]) {
        return Object.values({ All: this.ALL_BRANDS, ...this.siteMap[site_id].brands });
      }
      return [];
    },
    brand() {
      if (!this.$route.params.brand_id) {
        return this.brandsInfo[0];
      }

      return this.brandsInfo.find((brand) => brand.id === this.$route.params.brand_id);
    },
  },

  watch: {
    brands: {
      async handler(newValue) {
        if (!this.site_id && this.sites[0] && this.sites[0].id) {
          this.$router.replace({
            name: 'orders-site',
            params: {
              site_id: this.sites[0].id,
            },
          });
        }
        if (!newValue || !newValue.length) return;
        await this.getBrandsInfo();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('sites', ['getLocationBrand']),
    setDefaultFilterDate() {
      const startDateObject = DateTime.local().startOf('day');
      this.filterDates = {
        startDateObject,
      };
    },
    updateFilterDates(dates) {
      const startDateObject = DateTime.fromISO(dates.start);
      const endDateObject = DateTime.fromISO(dates.end);
      this.filterDates = {
        startDateObject,
        endDateObject,
      };
    },
    async setSite(id) {
      this.$router.replace({
        name: 'orders-site',
        params: {
          site_id: id,
        },
      });
    },
    async setBrand(id) {
      if (id === this.ALL_BRANDS.id) {
        this.setSite(this.site_id);
      } else {
        this.$router.replace({
          name: 'orders-brand',
          params: {
            site_id: this.site_id,
            brand_id: id,
          },
        });
      }
    },
    async getBrandsInfo() {
      this.loadingBrands = true;
      try {
        const brands = await Promise.all(
          this.brands.filter((e) => e.id !== this.ALL_BRANDS.id).map((e) => this.getBrand(e)),
        );
        this.brandsInfo = brands;
        if (brands.length) {
          if (this.$route.query.dates) {
            const [start, end] = this.$route.query.dates.split('_');
            this.updateFilterDates({ start, end });
          } else {
            this.setDefaultFilterDate();
          }
        }
      } catch (err) {
        console.error(err);
        this.$toast.error('Could not load brand information');
      }
      this.loadingBrands = false;
    },

    async getBrand({ id }) {
      const brand = await this.getLocationBrand({ id });
      if (brand.style && brand.style.logo && brand.style.logo.includes('http')) {
        brand.image = brand.style.logo;
      }
      return brand;
    },
    goOrdersBrand({ id }) {
      this.$router.push({
        name: 'orders-brand',
        params: {
          brand_id: id,
          site_id: this.site_id,
        },
      });
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>
