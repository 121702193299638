import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { "grid-list-xl": "" } },
    [
      _c(
        VLayout,
        { attrs: { row: "", wrap: "" } },
        [
          _c(VFlex, { attrs: { xs8: "" } }, [
            _c("div", { staticClass: "H3-Primary-Left" }, [_vm._v("Orders")])
          ])
        ],
        1
      ),
      _c(
        VLayout,
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            VFlex,
            { attrs: { xs6: "" } },
            [
              _c(VAutocomplete, {
                attrs: {
                  solo: "",
                  "single-line": "",
                  label: "Sites",
                  "prepend-inner-icon": "mdi-magnify",
                  items: _vm.sites,
                  "item-text": "name",
                  "item-value": "id",
                  value: _vm.site_id,
                  flat: "",
                  "append-icon": ""
                },
                on: { change: _vm.setSite }
              })
            ],
            1
          ),
          _vm.site_id
            ? _c(
                VFlex,
                { attrs: { xs6: "" } },
                [
                  _c(VAutocomplete, {
                    attrs: {
                      solo: "",
                      "single-line": "",
                      label: "Brand",
                      items: _vm.brands,
                      "item-text": "name",
                      "item-value": "id",
                      value: _vm.brand_id ? _vm.brand_id : _vm.ALL_BRANDS.id,
                      flat: ""
                    },
                    on: { change: _vm.setBrand }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        VLayout,
        { attrs: { wrap: "" } },
        [
          _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _vm.brand && _vm.filterDates.startDateObject
                ? _c("orders-list", {
                    key: _vm.brand_id,
                    attrs: {
                      brand: _vm.brand,
                      brandsInfo: _vm.brandsInfo,
                      filterDates: _vm.filterDates
                    },
                    on: { "update:filterDates": _vm.updateFilterDates }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }