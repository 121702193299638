<template>
  <v-btn
    class=" button-styling"
    color="primary"
    :disabled="loading"
    :loading="isExporting"
    @click="eventRouter"
  >
    <v-icon>mdi-download</v-icon>
    Export
  </v-btn>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { mapState } from 'vuex';
import { displayDate, displayStatus, orderComparator } from './helpers';
import { OrderType } from '../../constants';

export default {
  props: {
    brand: { type: Object, required: true },
    dates: { type: String, required: true },
    headers: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    loading: { type: Boolean, required: true },
    orders: {
      type: Array,
      default: () => {
        return [];
      },
    },
    orderType: { type: String, required: true },
    asPdf: { type: Boolean, required: true },
  },
  data() {
    return {
      isExporting: false,
      defaultOrderComparator: orderComparator('station', true),
    };
  },
  computed: {
    ...mapState('sites', ['siteMap']),
    headersToFormatForCsv() {
      return [
        'station',
        'name',
        'payment_type',
        'pickup',
        'ordered',
        'delivery',
        'destination',
        'instructions',
        'progress',
      ];
    },
  },
  methods: {
    eventRouter() {
      if (this.asPdf) {
        this.exportAsPdf();
      } else {
        this.exportAsCsv();
      }
    },
    async exportAsPdf() {
      this.isExporting = true;
      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      const siteName = this.siteMap[this.$route.params.site_id].name;
      const orderType =
        this.orderType === 'all'
          ? 'All'
          : OrderType[this.orderType].charAt(0).toUpperCase() + OrderType[this.orderType].substr(1);

      // Base
      const documentDefinition = {
        pageOrientation: 'landscape',
        pageMargins: [0, 28],
        content: [
          {
            columns: [
              { text: 'Orders Report', style: 'header' },
              { text: ` ${this.dates}`, style: 'date' },
            ],
          },

          {
            columns: [
              {
                text: `${siteName}`,
                style: 'subheader',
              },
              { text: `Order Type: ${orderType}`, style: 'subHeaderRightFloat' },
            ],
          },
          {
            style: 'table',
            table: {
              widths: [],
              headerRows: 1,
              body: [],
            },
            layout: 'noBorders',
          },
        ],

        /* Some duplication of code, but the styling for pdffMake does not work like css
        styling so classes cannot be used */
        styles: {
          header: {
            fontSize: 30,
            bold: true,
            margin: [25, 0, 0, 0],
            color: '#0a43a7',
            font: 'Roboto',
          },
          subheader: {
            font: 'Roboto',
            fontSize: 12,
            color: '#212121',
            bold: true,
            lineHeight: 1.67,
            margin: [30, 10, 0, 0],
            characterSpacing: 3,
          },
          subHeaderRightFloat: {
            font: 'Roboto',
            fontSize: 12,
            color: '#212121',
            bold: true,
            lineHeight: 1.67,
            margin: [210, 0, 30, 0],
            characterSpacing: 3,
            alignment: 'right',
          },
          date: {
            font: 'Roboto',
            fontSize: 16,
            color: '#0a43a7',
            bold: true,
            lineHeight: 1.67,
            margin: [280, 0, 0, 0],
            characterSpacing: 3,
          },
          table: {
            margin: [0, 15, 0, 0],
          },
          tableHeader: {
            bold: true,
            fontSize: 10,
            color: 'white',
            fillColor: '#0d73d8',
            margin: orderType === 'Pickup' ? [0, 20, 0, 4] : [0, 20, 0, 4],
            alignment: 'left',
          },
          tableCellEven: {
            fontSize: 8,
            margin: [0, 12, 0, 12],
          },
          tableCellOdd: {
            fontSize: 8,
            margin: [0, 12, 0, 12],
            fillColor: '#F4F4F4',
          },
        },
        defaultStyle: {},
      };

      // Adding content to table
      const tableDefinition = documentDefinition.content[2].table.body;

      this.headers.forEach(() => {
        documentDefinition.content[2].table.widths.push('*');
      });

      // Adding headers
      tableDefinition.push(
        this.headers.map((header) => ({
          text: header.text,
          alignment: 'center',
          style: 'tableHeader',
        })),
      );

      // Adding rows
      this.orders.sort(this.defaultOrderComparator).forEach((order, index) => {
        const row = this.headers.map((header) => {
          let tableCell = order[header.value];

          if (tableCell && header.value === 'progress') {
            tableCell = displayStatus(order[header.value]);
          } else if (['ordered', 'pickup', 'delivery'].includes(header.value)) {
            tableCell = displayDate(order[header.value], this.brand.timezone);
          } else if (tableCell && header.value === 'total') {
            tableCell = tableCell ? `$${tableCell}` : '';
          } else if (header.value === 'instructions') {
            tableCell = tableCell || 'N/A';
          }

          return {
            alignment: 'center',
            text: tableCell || '',
            style: index % 2 === 0 ? 'tableCellEven' : 'tableCellOdd',
          };
        });
        tableDefinition.push(row);
      });

      const filename = `${orderType} orders ${this.$route.params.brand_id ? this.brand.name : ''} ${
        this.dates
      }`
        .replace(/\s+/g, '-')
        .toLowerCase();
      pdfMake.createPdf(documentDefinition).download(`${filename}.pdf`);

      this.isExporting = false;
    },
    exportAsCsv() {
      const pre_processed_data = this.orders.sort(this.defaultOrderComparator).map((order) => {
        const formatted_order = {};
        this.headers.forEach((header) => {
          let tableCell = order[header.value];

          if (tableCell && header.value === 'progress') {
            tableCell = displayStatus(order[header.value]);
          } else if (['ordered', 'pickup', 'delivery'].includes(header.value)) {
            tableCell = displayDate(order[header.value], this.brand.timezone);
            if (tableCell.length === 7) {
              // Format like this for proper alignment in csv
              tableCell = `0${tableCell}`;
            }
          } else if (tableCell && header.value === 'total') {
            tableCell = tableCell ? `$${tableCell}` : '';
          } else if (header.value === 'instructions') {
            tableCell = tableCell || 'N/A';
          }

          // format string data to avoid breaking CSV
          if (tableCell && this.headersToFormatForCsv.includes(header.value)) {
            tableCell = tableCell.replace(/"/g, '""'); // escape quotes
            tableCell = `"${tableCell}"`; // wrap in quotes to escape commas & newlines
          }

          formatted_order[header.value] = tableCell;
        });
        return formatted_order;
      });
      const csv = [
        Object.keys(pre_processed_data[0]).join(','),
        ...pre_processed_data.map((item) => Object.values(item).join(',')),
      ]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '');

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      const link = document.createElement('a');
      link.setAttribute('href', URL.createObjectURL(blob));
      const dates = this.dates.replace(' ', '-');
      const brands = this.$route.params.brand_id ? this.brand.name : 'all';
      link.setAttribute('download', `${brands}-orders-${dates}.csv`);
      link.click();
    },
  },
};
</script>

<style scoped>
div.btn__content {
  padding: 50px;
}

div.card__actions .btn {
  min-width: 131px;
}

.button-styling {
  font-family: Avenir !important;
  padding-top: 25px;
  padding-bottom: 25px;
}
</style>
