<template>
  <v-dialog v-model="stateSync" width="800" :persistent="refundLoading">
    <v-card v-if="stateSync">
      <v-card-title class="H4-Secondary-Center" primary-title
        >Order #{{ order.details.display_id }}</v-card-title
      >
      <v-card-text>
        <v-form ref="form">
          <v-layout row wrap>
            <loading-wrapper :loading="loading">
              <v-flex xs12>
                <v-tabs hide-slider>
                  <v-tab href="#tab-details">Details</v-tab>
                  <v-tab href="#tab-raw">Raw</v-tab>
                  <v-tab-item value="tab-details">
                    <v-card class="card-custom">
                      <v-container grid-list-xl>
                        <v-layout wrap justify-space-between>
                          <v-flex shrink>
                            <div class="Body-1-Selected-On-Surface-Medium-Emphasis-Left">Name</div>
                            <div class="H6-Selected-On-Surface-High-Emphasis-Left">
                              {{ order.pickup_name }}
                            </div>
                          </v-flex>
                          <v-flex shrink>
                            <div class="Body-1-Selected-On-Surface-Medium-Emphasis-Left">
                              Refund Status
                            </div>
                            <div class="H6-Selected-On-Surface-High-Emphasis-Left">
                              {{ refundStatus }}
                            </div>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap>
                          <v-flex xs12>
                            <v-divider />
                          </v-flex>
                        </v-layout>
                        <v-layout justify-space-between v-if="partitioned_cart.length">
                          <v-flex xs2>
                            <span class="Body-1-Secondary-Left">Station</span>
                          </v-flex>
                          <v-flex xs2>
                            <span class="Body-1-Secondary-Left">Refund</span>
                          </v-flex>
                        </v-layout>
                        <v-layout v-if="partitioned_cart.length">
                          <table class="orderTable">
                            <tbody>
                              <template v-for="(item, index) in partitioned_cart">
                                <tr
                                  class="brand-header"
                                  :key="`brand-${index}`"
                                  v-if="
                                    index === 0 ||
                                      (item.meta &&
                                        partitioned_cart[index - 1].meta &&
                                        item.meta.brand !== partitioned_cart[index - 1].meta.brand)
                                  "
                                >
                                  <h1 class="Subtitle-1-Black-Medium-Emphasis-Left">
                                    {{ brand_names[item.meta.brand || order.location_brand] }}
                                  </h1>
                                </tr>
                                <tr :key="index">
                                  <td class="quantity">{{ item.quantity }}x</td>
                                  <td class="item-column">
                                    {{ (item && item.label && item.label.en) || '' }}
                                    <br />
                                    {{ getModifiers(item) }}
                                  </td>
                                  <td class="price">{{ item._order.subtotal | currency }}</td>
                                  <td class="checkbox-column">
                                    <v-layout justify-center class="checkbox">
                                      <v-checkbox
                                        v-model="refundItems"
                                        :value="item"
                                        :disabled="!canRefund"
                                      >
                                      </v-checkbox>
                                    </v-layout>
                                  </td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </v-layout>
                        <v-layout v-if="refundedItems.length">
                          <v-flex xs12>
                            <template>
                              <tr>
                                <h1 class="Subtitle-1-Black-Medium-Emphasis-Left">
                                  Refunded Items
                                </h1>
                              </tr>
                              <table class="orderTable">
                                <tbody>
                                  <template v-for="(item, index) in refundedItems">
                                    <tr :key="'refund' + index">
                                      <td class="quantity refund">{{ item.quantity }}x</td>
                                      <td class="refund">
                                        {{ item.label.en }}
                                        <br />
                                        {{ getModifiers(item) }}
                                      </td>
                                      <td></td>
                                      <td style="text-align: right">Refunded</td>
                                    </tr>
                                  </template>
                                </tbody>
                              </table>
                            </template>
                          </v-flex>
                        </v-layout>

                        <v-layout wrap>
                          <v-flex xs12>
                            <v-divider />
                          </v-flex>
                        </v-layout>
                        <v-layout wrap justify-space-between v-if="order.details.instructions">
                          <v-flex xs12>
                            <div class="Body-1-Selected-On-Surface-Medium-Emphasis-Left">
                              Delivery Instruction
                            </div>
                            <div>
                              <p>{{ order.details.instructions }}</p>
                            </div>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap v-if="order.details.instructions">
                          <v-flex xs12>
                            <v-divider />
                          </v-flex>
                        </v-layout>
                        <payment-breakdown :cart.sync="cart" />
                        <template v-if="cart && cart.payment_method">
                          <v-divider></v-divider>
                          <tr>
                            <h1 class="Body-1-Secondary-Left sectionHeader">Payment Method(s)</h1>
                          </tr>
                          <v-layout justify-center>
                            <table class="paymentMethods">
                              <tbody>
                                <template v-for="(item, index) in getOrderPaymentType">
                                  <tr :key="(index + 2) * 10">
                                    <td>
                                      {{ item.type }}
                                    </td>
                                    <td>
                                      {{ (item.total || 0) | currency }}
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </v-layout>
                        </template>
                        <v-layout justify-center>
                          <table>
                            <tr></tr>
                          </table>
                        </v-layout>
                      </v-container>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item value="tab-raw">
                    <v-card class="card-custom">
                      <v-container grid-list-xl class="raw-container">
                        <div class="title">
                          <span class="section-title">Shopping cart</span>
                        </div>
                        <v-layout wrap>
                          <v-flex xs12>
                            <json-viewer :value="cart"></json-viewer>
                          </v-flex>
                        </v-layout>
                      </v-container>
                      <v-container grid-list-xl class="raw-container">
                        <div class="title">
                          <span class="section-title">Order</span>
                        </div>
                        <v-layout wrap>
                          <v-flex xs12>
                            <json-viewer :value="order"></json-viewer>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-flex>
            </loading-wrapper>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions v-if="refundItems.length > 0">
        <v-container grid-list-xl>
          <v-form ref="form">
            <v-layout align-center justify-space-between>
              <v-flex shrink>
                <span class="Body-1-Selected-On-Surface-Medium-Emphasis-Left">
                  Refund Sub Total:
                  <br />
                </span>
                <span class="H6-Selected-On-Surface-High-Emphasis-Left">
                  {{ refundSubTotal | currency }}
                </span>
              </v-flex>
              <v-flex>
                <v-select
                  :items="refundReason"
                  label="Refund Reason"
                  v-model="reason"
                  outline
                  :rules="reasonRules"
                >
                </v-select>
              </v-flex>
              <v-flex>
                <v-text-field
                  outline
                  label="Enter Account Password"
                  v-model="password"
                  type="password"
                  :error-messages="passwordError"
                  :rules="passwordRules"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card-actions>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" flat @click="close" v-show="!refundLoading">close</v-btn>
        <v-btn
          color="primary"
          flat
          @click="refund"
          :loading="refundLoading"
          v-show="refundItems.length > 0"
          >refund
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import add from 'lodash/add';
import differenceBy from 'lodash/differenceBy';
import { mapState, mapMutations, mapGetters } from 'vuex';
import cdlid from '@compassdigital/id';
import isEmpty from 'lodash/isEmpty';
import rules from '@/rules';
import { itemPrice } from './helpers';
import PaymentBreakdown from './paymentBreakdown';

export default {
  components: {
    PaymentBreakdown,
  },
  props: ['state', 'orderData', 'country', 'brand', 'brandsInfo'],

  data: () => ({
    loading: false,
    cart: {},
    order: {},
    originalCart: {},
    refundItems: [],
    refundLoading: false,
    password: '',
    passwordError: '',
    passwordRules: [rules.required('Authentication required for refunds')],
    reason: '',
    reasonRules: [rules.required('Refund reason is required')],
    refundReason: [
      'Item Quality',
      'Incorrect item',
      'Duplicate Item',
      'Item not found',
      'Wrong customer',
    ],
    brands: [],
    brand_names: {},
    partitioned_cart: [],
  }),

  computed: {
    ...mapState('adminPanel', ['user', 'userRealm']),
    ...mapState('users', ['customPermissions']),
    ...mapGetters('adminPanel', ['hasRole']),
    canRefund() {
      if (this.isCanada) {
        return false;
      }
      if (this.user.permissions.scopes.includes('*:*:*')) return true;
      if (this.user.permissions.scopes.includes(this.customPermissions.refundOrders)) {
        return true;
      }
      if (this.user.permissions.scopes.includes('*:order:*')) {
        return true;
      }
      return false;
    },
    stateSync: {
      get() {
        return this.state;
      },
      set(v) {
        this.$emit('update:state', v);
      },
    },
    refundSubTotal() {
      const items = this.refundItems;
      return items.reduce((prev, curr) => add(prev, curr._order.subtotal), 0);
    },
    refundStatus() {
      if (this.order.meta.refunds && this.cart.items) {
        if (this.order.meta.refunds.length > 0 && this.cart.items.length === 0) {
          return 'Fully Refunded';
        }
        if (this.order.meta.refunds.length > 0 && this.cart.items.length > 0) {
          return 'Partial Refund';
        }
      }

      return 'None';
    },
    orderItems() {
      if (this.cart.items) {
        return this.cart.items.map((item) => {
          return {
            ...item,
            _order: {
              subtotal: itemPrice(item) * item.quantity,
            },
          };
        });
      }
      return [];
    },
    isCanada() {
      return this.country === 'CA';
    },
    refundedItems() {
      return differenceBy(this.originalCart.items, this.cart.items, '_index');
    },
    originalCartId() {
      if (this.order.meta.refunds) {
        const sortedCarts = [...this.order.meta.refunds].sort(
          (a, b) => new Date(a.refund_date) - new Date(b.refund_date),
        );
        return sortedCarts[0] ? sortedCarts[0].original_shoppingcart : null;
      }

      return null;
    },
    is_market_place_order() {
      const has_properties =
        this.order.meta &&
        this.order.meta.market_place_stations &&
        this.order.meta.market_place_label;
      return !!has_properties;
    },
    getOrderPaymentType() {
      const cart_payment = this.cart.payment_method || {};
      const payments = [];

      if (!isEmpty(cart_payment.credit_card)) {
        payments.push({
          type: cart_payment.credit_card.card_type || {},
          total: cart_payment.credit_card.total || 0,
        });
      }

      if (!isEmpty(cart_payment.badge_pay)) {
        payments.push({
          type: cart_payment.badge_pay?.name
            ? `${cart_payment.badge_pay?.name} (Badge Pay)`
            : 'Badge Pay',
          total: cart_payment.badge_pay?.total || 0,
        });
      }

      if (!isEmpty(cart_payment.digital_wallet_pay)) {
        payments.push({
          type: cart_payment.digital_wallet_pay.wallet_type || {},
          total: cart_payment.digital_wallet_pay.total || 0,
        });
      }

      if (!isEmpty(cart_payment.mealplan)) {
        const mealplan_name = this.order.mealplan && this.order.mealplan.name;
        if (this.order.meta.transaction.drained_tender_breakdown) {
          this.order.meta.transaction.drained_tender_breakdown.forEach((drained_tender) => {
            payments.push({
              type: `Campus Card (MP ID: ${drained_tender.name})`,
              total: drained_tender.amount || 0,
            });
          });
        } else {
          const payment = this.formatMealplanPayment(
            cart_payment.mealplan.name || mealplan_name || '',
            cart_payment.mealplan.tender,
          );
          payments.push({ type: payment || {}, total: cart_payment.mealplan.total || 0 });
        }
      }

      if (!isEmpty(cart_payment.meal_swipes)) {
        const payment = this.formatMealplanPayment(
          cart_payment.meal_swipes.tender_name,
          cart_payment.meal_swipes.tender,
        );
        payments.push({ type: payment || {}, total: cart_payment.meal_swipes.total || 0 });
      }
      if (!payments.length) {
        if (this.order.payment?.credit_card?.card_type) {
          payments.push({
            type: this.order.payment?.credit_card?.card_type,
          });
        }
        if (this.order?.mealplan?.name) {
          const payment = this.formatMealplanPayment(
            this.order.mealplan.name,
            this.order.mealplan.tender,
          );
          payments.push({ type: payment || {} });
        }
      }
      // filter out unused payment methods
      const used_methods = payments.filter((item) => JSON.stringify(item.type) !== '{}');
      if (used_methods.length === 1) {
        used_methods[0].total = this.cart.total.amount;
      }
      return used_methods;
    },
  },

  methods: {
    ...mapMutations('adminPanel', ['setLogin']),
    getModifiers(item) {
      return item.options
        .map((option) => option.items.map((optionItem) => optionItem.label.en.trim()))
        .flat()
        .join(', ');
    },
    close() {
      this.refundItems = [];
      this.stateSync = false;
    },
    async refund() {
      if (this.$refs.form.validate()) {
        try {
          await this.$store.dispatch('adminPanel/login', {
            email: this.user.email,
            password: this.password,
          });
          this.passwordError = '';
        } catch (err) {
          this.passwordError = 'Incorrect credentials';
          return;
        }
        try {
          this.refundLoading = true;
          await this.api.patch(`/order/${this.order.id}/refund`, {
            refunds: this.refundItems.map((e) => ({ ...e, reason: this.reason })),
          });
          this.$emit('refund');
          this.$toast('Refund successfully processed');
          this.close();
        } catch (err) {
          this.$toast.error(`Could not refund, ${err.response.data.error}`);
        }
        this.refundLoading = false;
      }
    },
    async fetchCartAndOrder() {
      this.password = '';
      this.passwordError = '';
      this.reason = '';
      this.loading = true;
      this.originalCart = {};
      try {
        const [cart, order] = await Promise.all([
          this.api.get(`/shoppingcart/${this.orderData.shoppingcart}`).then((r) => r.data),
          this.api.get(`/order/${this.orderData.id}`).then((r) => r.data),
        ]);
        this.cart = cart;
        this.order = order;
        if (this.order.meta.refunds) {
          const { data: originalCart } = await this.api.get(`/shoppingcart/${this.originalCartId}`);
          this.originalCart = originalCart;
        }
      } catch (err) {
        this.$toast.error('Could not load shopping cart');
      }
      this.loading = false;
    },
    format_key(key) {
      key = key.split('_');
      key = key.map((item) => item[0].toUpperCase() + item.substring(1));
      return key.join(' ');
    },
    async setBrands() {
      if (!this.is_market_place_order) {
        this.brands = [this.brand];
        this.brands.forEach((item) => {
          this.brand_names[item.id] = item.name;
        });
        return;
      }

      this.order.meta.market_place_stations.forEach((id) => {
        this.brandsInfo.forEach((brand) => {
          if (id === brand.id) {
            this.brand_names[brand.id] = brand.name;
          }
        });
      });
    },
    partitionItems() {
      if (this.brands.length === 1 || this.cart.items.length === 1) {
        this.partitioned_cart = this.orderItems.slice();
        return;
      }
      // Sort the items based in brand alphabetical order
      const sorted_cart_items = this.orderItems.slice().sort((a, b) => {
        if (a.meta.brand_label.en < b.meta.brand_label.en) return -1;
        if (a.meta.brand_label.en > b.meta.brand_label.en) return 1;
        return 0;
      });
      this.partitioned_cart = sorted_cart_items;
    },
    formatMealplanPayment(name, tenderId) {
      let payment = name;
      const tender = cdlid(tenderId);
      if (tender) {
        payment += ` (MP ID: ${tender.id})`;
      }
      return payment;
    },
  },

  watch: {
    orderData(curr, prev) {
      if (!this.order.id) {
        this.order = curr;
      }
      if (prev?.id === curr?.id) return;
      this.fetchCartAndOrder();
      this.setBrands();
    },
    cart(curr) {
      if (curr && curr.items) {
        this.partitionItems();
      }
    },
    orderItems(curr) {
      if (curr && curr.length > 0) {
        this.partitionItems();
      }
    },
  },
};
</script>

<style scoped>
.orderTable {
  width: 100%;
  padding: 0px 10px;
}

.orderTable >>> .checkbox-column {
  padding: 0px 50px;
}

.checkbox >>> div.layout {
  height: 60px;
}

.refund {
  text-decoration-line: line-through;
}

.orderTable td.refund {
  padding: 10px 70px;
  height: 60px;
}

.paymentMethods td {
  padding: 10px 50px;
}

.sectionHeader {
  margin-top: 20px;
}

.orderTable >>> h1 {
  width: 200px;
}

.orderTable >>> td.item-column {
  width: 700px !important;
  padding: 10px 20px;
}

.orderTable >>> td.quantity {
  max-width: 30px;
  width: 10px !important;
  padding: 10px 70px;
}

.orderTable >>> td.price {
  width: 50px !important;
  padding: 10px 20px;
}

.raw-container {
  padding: 0;
}

.raw-container:not(:last-child) {
  border-bottom: 1px solid #e0e0e0;
}

.jv-container .jv-code {
  padding: 20px;
}

.card-custom .title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0;
  height: 30px;
  padding-top: 10px;
}

.section-title {
  color: #090c9b;
}
</style>
