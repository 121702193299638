import DateTime from 'luxon/src/datetime.js';

export const displayDate = (
  dates,
  brandTimeZone,
  format = DateTime.DATETIME_SHORT,
  customFormat = false, // to use a custom format (not a DateTime preset), set this to 'true'
) => {
  if (!dates) {
    return 'N/A';
  }

  return dates
    .split('/')
    .map((date) => {
      let formattedDate = DateTime.fromISO(date).setZone(brandTimeZone || 'local');
      if (customFormat) {
        formattedDate = formattedDate.toFormat(format);
      } else {
        formattedDate = formattedDate.toLocaleString(format);
      }
      return formattedDate;
    })
    .join(' - ');
};

export const displayStatus = (is) => {
  if (is.delivered) {
    return 'Delivered';
  }
  if (is.out_for_delivery) {
    return 'Out for Delivery';
  }
  if (is.ready) {
    return 'Ready for pickup';
  }
  if (is.in_progress) {
    return 'In Progress';
  }
  if (is.accepted) {
    return 'Accepted';
  }
  return 'N/A';
};

export const orderComparator = (key, isDesc) => {
  return (a, b) => {
    // custom sort when ordering by 'station'
    if (key === 'station') {
      if (a.station < b.station) {
        return isDesc ? -1 : 1;
      }

      if (a.station > b.station) {
        return isDesc ? 1 : -1;
      }

      // if both orders are from the same station, sort by order id, desc
      return a.id < b.id ? -1 : 1;
    }

    // default sort behaviour
    if (isDesc) {
      return a[key] > b[key] ? -1 : 1;
    }
    return a[key] < b[key] ? -1 : 1;
  };
};

export const formatStartEndDates = (start, end, delimiter) => {
  return start === end || !end ? start : `${start}${delimiter}${end}`;
};

export const itemPrice = (item, shouldIncludeModifiers = true) => {
  let total = item.price?.amount || 0;

  if (item.sale_price?.active && item.sale_price.amount) total = item.sale_price.amount;

  if (item.options?.length === 0) return Number(total.toFixed(2));

  if (shouldIncludeModifiers) {
    // eslint-disable-next-line no-unused-expressions
    item.options?.forEach((itemOption) => {
      if (itemOption.items) {
        total = itemOption.items.reduce(
          (itemTotal, option) => itemTotal + (option.price?.amount || 0),
          total,
        );
      }
    });
  }

  return Number(total.toFixed(2));
};
