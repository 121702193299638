import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VBtn,
    {
      staticClass: " button-styling",
      attrs: {
        color: "primary",
        disabled: _vm.loading,
        loading: _vm.isExporting
      },
      on: { click: _vm.eventRouter }
    },
    [_c(VIcon, [_vm._v("mdi-download")]), _vm._v("\n  Export\n")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }