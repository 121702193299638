import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "card-custom" },
    [
      _c(
        VContainer,
        { attrs: { "grid-list-xl": "" } },
        [
          _c("orders-dialog", {
            attrs: {
              state: _vm.dialog.state,
              orderData: _vm.dialog.order,
              country: _vm.country,
              brand: _vm.getRelevantBrand(_vm.dialog.order),
              brandsInfo: _vm.brandsInfo
            },
            on: {
              "update:state": function($event) {
                return _vm.$set(_vm.dialog, "state", $event)
              },
              refund: _vm.refunded
            }
          }),
          _c(
            VLayout,
            { attrs: { wrap: "", "justify-space-between": "" } },
            [
              _c(
                VFlex,
                { attrs: { xs4: "" } },
                [
                  _c(VTextField, {
                    attrs: {
                      "prepend-inner-icon": "mdi-magnify",
                      label: "Search for anything"
                    },
                    model: {
                      value: _vm.filter,
                      callback: function($$v) {
                        _vm.filter = $$v
                      },
                      expression: "filter"
                    }
                  }),
                  _c(
                    VRadioGroup,
                    {
                      attrs: { row: "", label: "Export as:" },
                      model: {
                        value: _vm.asPdf,
                        callback: function($$v) {
                          _vm.asPdf = $$v
                        },
                        expression: "asPdf"
                      }
                    },
                    [
                      _c(VRadio, {
                        attrs: { label: ".CSV", value: false, color: "#4020ae" }
                      }),
                      _c(VRadio, {
                        attrs: { label: ".PDF", value: true, color: "#4020ae" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VFlex,
                { attrs: { xs3: "" } },
                [
                  _c(VSelect, {
                    attrs: {
                      label: "Order Type",
                      items: _vm.orderTypeOptions,
                      value: _vm.orderType
                    },
                    on: { input: _vm.changeOrderType }
                  })
                ],
                1
              ),
              _c(
                VFlex,
                { attrs: { xs4: "" } },
                [
                  _c("range-date-picker", {
                    attrs: {
                      dates: _vm.dateStrings,
                      reportDateValue: _vm.datesDisplayValue,
                      maxRange: _vm.maxOrderDateRange,
                      reportDateLabel: "Date(s)"
                    },
                    on: {
                      "update:dates": function($event) {
                        _vm.dateStrings = $event
                      }
                    }
                  }),
                  _c("orders-export", {
                    staticClass: "export-button",
                    attrs: {
                      dates: _vm.datesDisplayValue,
                      headers: _vm.headers,
                      loading: _vm.loading,
                      brand: _vm.brand,
                      orders: _vm.exportedOrders,
                      orderType: _vm.orderType,
                      asPdf: _vm.asPdf
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            VLayout,
            { attrs: { wrap: "" } },
            [
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c(
                    VDataTable,
                    {
                      ref: "orders-table",
                      attrs: {
                        headers: _vm.headers,
                        items: _vm.filteredOrders,
                        loading: _vm.loading,
                        search: _vm.filter,
                        pagination: _vm.pagination,
                        "no-data-text": _vm.loading
                          ? "Please wait while data is loading"
                          : "No orders found",
                        "custom-sort": _vm.customTableSort
                      },
                      on: {
                        "update:pagination": function($event) {
                          _vm.pagination = $event
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "items",
                          fn: function(props) {
                            return [
                              _c(
                                "tr",
                                {
                                  staticClass: "clickable",
                                  on: {
                                    click: function($event) {
                                      return _vm.openDialog(props.item._raw)
                                    }
                                  }
                                },
                                [
                                  _c("td", [
                                    _vm._v(_vm._s(props.item.station))
                                  ]),
                                  _c("td", [_vm._v(_vm._s(props.item.id))]),
                                  _c("td", [_vm._v(_vm._s(props.item.name))]),
                                  _vm.orderType !== _vm.OrderTypes.pickup
                                    ? _c("td", [
                                        _vm._v(
                                          _vm._s(props.item.contact_number)
                                        )
                                      ])
                                    : _vm._e(),
                                  _c("td", [
                                    _vm._v(_vm._s(props.item.payment_type))
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.displayDate(
                                          props.item.ordered,
                                          _vm.brand.timezone
                                        )
                                      )
                                    )
                                  ]),
                                  _vm.orderType !== _vm.OrderTypes.delivery
                                    ? _c("td", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              props.item.pickup
                                                ? _vm.displayDate(
                                                    props.item.pickup,
                                                    _vm.brand.timezone
                                                  )
                                                : "N/A"
                                            ) +
                                            "\n              "
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.orderType !== _vm.OrderTypes.pickup
                                    ? _c("td", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              props.item.delivery
                                                ? _vm.displayDate(
                                                    props.item.delivery,
                                                    _vm.brand.timezone
                                                  )
                                                : "N/A"
                                            ) +
                                            "\n              "
                                        )
                                      ])
                                    : _vm._e(),
                                  _c("td", [
                                    _vm._v(_vm._s(props.item.destination))
                                  ]),
                                  _vm.orderType !== _vm.OrderTypes.pickup
                                    ? _c("td", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              props.item.instructions || "N/A"
                                            ) +
                                            "\n              "
                                        )
                                      ])
                                    : _vm._e(),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.displayStatus(props.item.progress)
                                      )
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v("$" + _vm._s(props.item.total))
                                  ])
                                ]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c(VProgressLinear, {
                        attrs: {
                          color: "blue",
                          indeterminate: "",
                          height: "7"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "progress",
                            fn: function() {
                              return undefined
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }