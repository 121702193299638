<template>
  <v-card class="card-custom">
    <v-container grid-list-xl>
      <orders-dialog
        :state.sync="dialog.state"
        :orderData="dialog.order"
        @refund="refunded"
        :country="country"
        :brand="getRelevantBrand(dialog.order)"
        :brandsInfo="brandsInfo"
      />
      <v-layout wrap justify-space-between>
        <v-flex xs4>
          <v-text-field
            prepend-inner-icon="mdi-magnify"
            label="Search for anything"
            v-model="filter"
          />
          <v-radio-group row label="Export as:" v-model="asPdf">
            <v-radio label=".CSV" :value="false" color="#4020ae"></v-radio>
            <v-radio label=".PDF" :value="true" color="#4020ae"></v-radio>
          </v-radio-group>
        </v-flex>
        <v-flex xs3>
          <v-select
            label="Order Type"
            :items="orderTypeOptions"
            :value="orderType"
            v-on:input="changeOrderType"
          />
        </v-flex>
        <v-flex xs4>
          <range-date-picker
            :dates.sync="dateStrings"
            :reportDateValue="datesDisplayValue"
            :maxRange="maxOrderDateRange"
            reportDateLabel="Date(s)"
          ></range-date-picker>
          <orders-export
            class="export-button"
            :dates="datesDisplayValue"
            :headers="headers"
            :loading="loading"
            :brand="brand"
            :orders="exportedOrders"
            :orderType="orderType"
            :asPdf="asPdf"
          ></orders-export>
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12>
          <v-data-table
            ref="orders-table"
            :headers="headers"
            :items="filteredOrders"
            :loading="loading"
            :search="filter"
            :pagination.sync="pagination"
            :no-data-text="loading ? 'Please wait while data is loading' : 'No orders found'"
            :custom-sort="customTableSort"
          >
            <v-progress-linear
              v-slot:progress
              color="blue"
              indeterminate
              height="7"
            ></v-progress-linear>
            <template v-slot:items="props">
              <tr @click="openDialog(props.item._raw)" class="clickable">
                <td>{{ props.item.station }}</td>
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.name }}</td>
                <td v-if="orderType !== OrderTypes.pickup">{{ props.item.contact_number }}</td>
                <td>{{ props.item.payment_type }}</td>
                <td>{{ displayDate(props.item.ordered, brand.timezone) }}</td>
                <td v-if="orderType !== OrderTypes.delivery">
                  {{ props.item.pickup ? displayDate(props.item.pickup, brand.timezone) : 'N/A' }}
                </td>
                <td v-if="orderType !== OrderTypes.pickup">
                  {{
                    props.item.delivery ? displayDate(props.item.delivery, brand.timezone) : 'N/A'
                  }}
                </td>
                <td>{{ props.item.destination }}</td>
                <td v-if="orderType !== OrderTypes.pickup">
                  {{ props.item.instructions || 'N/A' }}
                </td>
                <td>{{ displayStatus(props.item.progress) }}</td>
                <td>${{ props.item.total }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import cdlid from '@compassdigital/id';
import { mapActions } from 'vuex';
import DateTime from 'luxon/src/datetime.js';
import Interval from 'luxon/src/interval.js';
import rangeDatePicker from '@/components/rangeDatePicker';
import { displayDate, displayStatus, orderComparator, formatStartEndDates } from './helpers';
import ordersDialog from './ordersDialog';
import ordersExport from './ordersExport';
import { OrderType } from '../../constants';

export default {
  components: {
    ordersDialog,
    'orders-export': ordersExport,
    rangeDatePicker,
  },
  props: {
    brand: { type: Object, required: true },
    brandsInfo: { type: Array, required: true },
    filterDates: {
      type: Object,
      required: true,
      default: () => {
        return {
          startDateObject: { type: Object },
          endDateObject: { type: Object },
        };
      },
    },
  },
  computed: {
    dates: {
      get() {
        return this.filterDates;
      },
      async set(_dates) {
        this.menu = false;
        this.$emit('update:filterDates', _dates);
        const dates_query_param = formatStartEndDates(_dates.start, _dates.end, '_');
        if (dates_query_param !== this.$route.query.dates) {
          await this.$router.replace({
            name: this.selectedBrand ? 'orders-brand' : 'orders-site',
            params: {
              site_id: this.$route.params.site_id,
              brand_id: this.$route.params.brand_id,
            },
            query: {
              dates: dates_query_param,
            },
          });
        }
        this.getOrders();
      },
    },
    dateStrings: {
      get() {
        return {
          start: this.dates.startDateObject.toISODate(),
          end: this.dates.endDateObject && this.dates.endDateObject.toISODate(),
        };
      },
      set(_dates) {
        this.dates = _dates;
      },
    },
    datesDisplayValue() {
      return formatStartEndDates(this.dateStrings.start, this.dateStrings.end, ' to ');
    },
    country() {
      return this.brand.address.country === 'CANADA' ? 'CA' : this.brand.address.country;
    },
    headers() {
      const headers = [
        {
          text: 'Station',
          align: 'left',
          value: 'station',
        },
        {
          text: 'ID',
          align: 'left',
          value: 'id',
        },
        {
          text: 'Name',
          align: 'left',
          value: 'name',
        },
        {
          text: 'Contact Number',
          align: 'left',
          value: 'contact_number',
        },
        {
          text: 'Payment Type',
          align: 'left',
          value: 'payment_type',
        },
        {
          text: 'Time Placed',
          align: 'left',
          value: 'ordered',
        },
        {
          text: 'Pickup Time Slot',
          align: 'left',
          value: 'pickup',
        },
        {
          text: 'Delivery Time Slot',
          align: 'left',
          value: 'delivery',
        },
        {
          text: `${
            this.orderType === 'all'
              ? 'Delivery / Pickup'
              : OrderType[this.orderType].charAt(0).toUpperCase() +
                OrderType[this.orderType].substr(1)
          } Location`,
          align: 'left',
          value: 'destination',
        },
        {
          text: 'Delivery Instruction',
          align: 'left',
          value: 'instructions',
        },
        {
          text: 'Order Status',
          align: 'left',
          value: 'progress',
        },
        {
          text: 'Total',
          align: 'right',
          value: 'total',
        },
      ];

      // Delivery
      if (this.orderType === OrderType.delivery) {
        return headers.filter((filter) => !['pickup'].includes(filter.value));
      }

      // Pickup
      if (this.orderType === OrderType.pickup) {
        return headers.filter(
          (filter) => !['contact_number', 'delivery', 'instructions'].includes(filter.value),
        );
      }
      // Scan And Go
      if (this.orderType === OrderType.scan_and_go) {
        return headers.filter((filter) => !['scan_and_go'].includes(filter.value));
      }

      // All
      return headers;
    },
    orderTypeOptions() {
      return [
        { text: 'All', value: 'all' },
        {
          text: 'Delivery',
          value: OrderType.delivery,
          disabled: this.selectedBrand && !this.brand.is.delivery_supported, // enabled if no brand selected
        },
        {
          text: 'Pickup',
          value: OrderType.pickup,
          disabled: this.selectedBrand && this.brand.is.scan_and_go_supported, // enabled if no brand selected
        },
        {
          text: 'Scan And Go',
          value: OrderType.scan_and_go,
          disabled: this.selectedBrand && !this.brand.is.scan_and_go_supported, // enabled if no brand selecte_id;
        },
      ];
    },
    filteredOrders() {
      // Delivery
      if (this.orderType === OrderType.delivery) {
        return this.orders.filter((order) => order.type === OrderType.delivery);
      }

      // Pickup
      if (this.orderType === OrderType.pickup) {
        return this.orders.filter((order) => order.type === OrderType.pickup);
      }

      if (this.orderType === OrderType.scan_and_go) {
        return this.orders.filter((order) => order.type === OrderType.scan_and_go);
      }

      // All
      return this.orders;
    },
    exportedOrders() {
      if (this.$refs['orders-table']) {
        /*
          Pulling out the customFilter fn from the v-data-table component
          to use it to pass the results to the PDF Export component.
        */
        const { customFilter, headers, filter, search } = this.$refs['orders-table'];
        return customFilter(this.filteredOrders, search, filter, headers);
      }

      return this.filteredOrders;
    },
    selectedBrand() {
      return this.$route.params.brand_id;
    },
  },

  data: () => ({
    filter: '',
    orders: [],
    loading: true,
    pagination: {
      rowsPerPage: 10,
      sortBy: 'station',
      descending: true,
    },
    menu: false,
    orderType: localStorage.orderTypeFilter ? localStorage.orderTypeFilter : OrderType.pickup,
    OrderTypes: OrderType,
    dialog: {
      state: false,
      order: {},
    },
    asPdf: true,
    maxOrderDateRange: 7,
  }),

  watch: {
    $route(to, from) {
      if (to.params.site_id !== from.params.site_id) {
        this.getOrders();
      }
    },
  },

  methods: {
    ...mapActions('sites', ['getConfigPublic', 'getLocationGroup']),
    displayDate,
    displayStatus,
    orderComparator,
    changeOrderType(type) {
      localStorage.orderTypeFilter = type;
      this.orderType = type;
    },
    openDialog(order) {
      this.dialog.state = true;
      this.dialog.order = order;
    },
    async refunded() {
      this.dialog.order = null;
      await this.getOrders();
    },
    isBrandInMarketPlaceLocation(site, brandId) {
      return site.locations.some(
        (location) => !!location.market_place && location.brands.some(({ id }) => id === brandId),
      );
    },
    async getOrders() {
      this.loading = true;

      try {
        this.site = await this.getLocationGroup({ id: this.$route.params.site_id });
        const isInMarketPlace = this.isBrandInMarketPlaceLocation(this.site, this.selectedBrand);

        const apiEndpoint =
          this.selectedBrand && !isInMarketPlace
            ? `/order/location/brand/${this.selectedBrand}`
            : `/order/location/group/${this.$route.params.site_id}`;

        const pickup_start = this.filterDates.startDateObject;
        const pickup_end =
          (this.filterDates.endDateObject && this.filterDates.endDateObject.endOf('day')) ||
          pickup_start.endOf('day');
        const {
          data: { orders },
        } = await this.api.get(apiEndpoint, {
          params: {
            pickup_start: pickup_start.toMillis(),
            pickup_end: pickup_end.toMillis(),
            _query:
              '{orders{id,details,location_brand,requested_date,pickup_id,meta{transaction{id,amount,success,credit_card,digital_wallet_pay,mealplan,drained_tender_name,drained_tender_breakdown},refunds,market_place_stations,market_place_label,shoppingcart},pickup_name,date{created},is,payment{credit_card,digital_wallet_pay,badge_pay},mealplan,meal_swipes,shoppingcart}}',
            extended: !this.selectedBrand, // get all brands, even hidden, when no selected brand
            // if a marketplace location, filter all orders in this location that include this brand
            brand: isInMarketPlace && this.selectedBrand ? this.selectedBrand : undefined,
            all_statuses: true,
          },
        });

        const config = {};
        let configPromises = [];
        if (this.selectedBrand && !isInMarketPlace) {
          const brandId = this.selectedBrand;
          const configPromise = this.getBrandConfig(brandId).then((brandConfig, err) => {
            if (!err) {
              config[brandId] = brandConfig;
            }
          });
          configPromises.push(configPromise);
        } else {
          try {
            const brands = this.site.locations.flatMap((location) => location.brands);
            configPromises = brands.map((brand) =>
              this.getBrandConfig(brand.id).then((brandConfig, err) => {
                if (!err) {
                  config[brand.id] = brandConfig;
                }
              }),
            );
          } catch (err) {
            console.error(`Could not get location group info`, err);
            this.$toast.error('Error loading site information');
          }
        }
        await Promise.all(configPromises).then(() => {
          this.orders = this.formatOrdersTable(orders, config);
        });
      } catch (err) {
        if (typeof err === 'string') {
          this.$toast.error(err);
        } else {
          this.$toast.error('Could not get orders');
        }
      }
      this.loading = false;
    },
    async getBrandConfig(brandId) {
      try {
        return await this.getConfigPublic({ id: brandId });
      } catch (err) {
        console.error(`Error getting brand configuration: `, err);
        return {};
      }
    },
    formatOrdersTable(orders, config) {
      return orders.map((order) => {
        const orderType = order.details && order.details.order_type;
        const orderBrandId = order.location_brand;
        const orderBrandConfig = config[orderBrandId];
        let orderBrandInfo;

        // check is order is from markplace
        const isMarketplaceOrder =
          order.meta && order.meta.market_place_stations && order.meta.market_place_label;

        if (isMarketplaceOrder) {
          const stationNames = [];
          order.meta.market_place_stations.forEach((id) => {
            this.brandsInfo.forEach((brand) => {
              if (id === brand.id) {
                stationNames.push(brand);
              }
            });
          });

          orderBrandInfo = stationNames.map((station) => station.name).join(', ');
        } else {
          orderBrandInfo = this.brandsInfo.find((brand) => brand.id === orderBrandId);
        }

        // Base
        const obj = {
          contact_number: order.details.contact_number,
          delivery: undefined,
          destination: order.details.destination,
          id: parseInt(order.details.display_id, 10), // radix/base = 10, convert to integer for sorting
          instructions: order.details.instructions,
          name: order.pickup_name,
          ordered: order.date.created,
          pickup: undefined,
          progress: order.is,
          total: this.getOrderTotal(order),
          type: orderType || OrderType.pickup,
          _raw: order,
          station: !isMarketplaceOrder ? orderBrandInfo.name : orderBrandInfo,
          payment_type: this.getOrderPaymentType(order),
        };

        // Pickup
        if (orderType === OrderType.pickup) {
          obj.pickup = order.requested_date;
        }

        // Delivery
        if (orderType === OrderType.delivery) {
          if (orderBrandConfig.show_single_timeslot) {
            obj.delivery = DateTime.fromISO(order.requested_date)
              .setZone(orderBrandInfo.timezone || 'local')
              .toISO();
          } else {
            obj.delivery = Interval.fromDateTimes(
              DateTime.fromISO(order.requested_date).setZone(orderBrandInfo.timezone || 'local'),
              this.addDuration(
                order.requested_date,
                order.details.duration,
                orderBrandInfo.timezone,
              ),
            ).toISO();
          }
        }

        return obj;
      });
    },
    customTableSort(items, index, isDesc) {
      return items.sort(orderComparator(index, isDesc));
    },
    addDuration(date, duration, timezone) {
      const [hours, minutes, seconds] = duration.split(':').map(Number);
      return DateTime.fromISO(date)
        .plus({ hours, minutes, seconds })
        .setZone(timezone || 'local');
    },
    getOrderPaymentType(order) {
      const payments = [];

      if (order.payment?.credit_card?.card_type) {
        payments.push(order.payment.credit_card.card_type);
      }

      if (order.payment?.badge_pay?.name) {
        payments.push(`${order.payment.badge_pay.name} (Badge Pay)`);
      }

      if (order.payment && order.payment.digital_wallet_pay) {
        payments.push(order.payment.digital_wallet_pay);
      }

      if (order.mealplan) {
        const payment = this.formatMealplanPayment(
          order.mealplan.name,
          order.mealplan.tender,
          order.meta.transaction.drained_tender_name,
          order.meta.transaction.drained_tender_breakdown,
        );
        payments.push(payment);
      }

      if (order.meal_swipes) {
        const payment = this.formatMealplanPayment(
          order.meal_swipes.tender_name,
          order.meal_swipes.tender,
        );
        payments.push(payment);
      }

      return payments.join(', ') || 'N/A';
    },
    formatMealplanPayment(name, tenderId, drained_tender_name = '', drained_tender_breakdown = []) {
      const payment = name;
      const tender = cdlid(tenderId);
      if (!tender) return payment;
      if (tender.id === 'Campus Card') {
        if (drained_tender_breakdown.length > 0) {
          const drained_tenders = drained_tender_breakdown
            .map((drained_tender) => drained_tender.name)
            .join(', ');
          return `${payment} (MP ID: ${drained_tenders})`;
        }
        return `${payment} (MP ID: ${drained_tender_name})`;
      }
      return `${payment} (MP ID: ${tender.id})`;
    },
    getRelevantBrand(order) {
      return this.brandsInfo.find((brand) => brand.id === order.location_brand);
    },
    getOrderTotal(order) {
      const saved_in_order_total = order.meta?.shoppingcart?.total?.amount;
      if (saved_in_order_total !== undefined) return saved_in_order_total;
      // backwards compatibility before CDL-3600
      if (order.meal_swipes) {
        const dollar_total_after_swipes =
          // if secondary payment was credit
          order.meta?.transaction?.credit_card?.amount ||
          // if secondary payment was digitalpay
          order.meta?.transaction?.digital_wallet_pay?.amount ||
          // if secondary payment was mealplan
          order.meta?.transaction?.mealplan?.amount ||
          // if was just paid by mealswipes we show 0
          0;
        return dollar_total_after_swipes;
      }
      let transaction = order.meta?.transaction;
      if (order.meta?.refunds?.length > 0) {
        // this supports backwards compatibility. For old orders, if there were refunds, get the latest refund, amount value will be the transaction_remainder_amount
        transaction = order.meta.refunds.reduce((prev, current) => {
          return prev?.refund_date > current?.refund_date ? prev : current;
        });
      }
      // Payment or mealplan will show valid amount,
      // mx will show the number of meals instead of $value which is incorrect but this line should not be reached
      // for orders created after CDL-3600 is deployed
      // if this becomes an issue we can do old logic before deploy date and new after

      /* for new order refunds, we store the transaction_remainder_amount on order.meta.transaction */
      return order.meta.transaction.transaction_remainder_amount || transaction?.amount || 0;
    },
  },

  async mounted() {
    this.getOrders();
  },
};
</script>
<style scoped>
table.v-datatable tbody td {
  /* body-1-high-emphasis */
  font-family: Avenir;
  font-size: 14.1px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.55;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.87);
  padding: 20px 24px !important;
}

.v-datatable thead tr th {
  font-family: Avenir;
  font-size: 14.1px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: #090c9b;
}

td:last-child {
  text-align: right;
  white-space: nowrap;
}

.datepicker >>> .mdi-calendar-range:hover::before {
  color: #3076d8;
}

.datepicker >>> input,
.datepicker >>> .v-text-field__slot,
.datepicker >>> .v-input__slot {
  cursor: pointer;
}

.export-button {
  float: right;
  margin-right: 0px;
  margin-top: 15px;
}

.orders-table >>> table.v-table thead th.sortable {
  color: #090c9b;
}

.orders-table >>> .v-datatable thead th.column.sortable {
  color: #fff;
}
</style>

<style>
/* Moved styling for the table to a global scope since it is an external package,
   having it in "scoped" styling would make v-data-table ignore it
   */

.v-datatable thead tr th {
  color: #090c9b !important;
  font-family: Avenir;
  font-size: 18.1px;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
}
</style>
