import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(VLayout, { attrs: { "justify-center": "" } }, [
    _c("table", { staticClass: "cost-table" }, [
      _c("tbody", [
        _c("tr", { attrs: { row: "", "justify-space-between": "" } }, [
          _c("td", [_vm._v("\n          Sub Total\n        ")]),
          _vm.cart && _vm.cart.sub_total
            ? _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm._f("currency")(_vm.cart.sub_total.amount)) +
                    "\n        "
                )
              ])
            : _vm._e()
        ]),
        _vm.cart.taxes.breakdown && _vm.cart.taxes.breakdown.gst
          ? _c("tr", [
              _c("td", [_vm._v("\n          GST/HST\n        ")]),
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm._f("currency")(_vm.cart.taxes.breakdown.gst)) +
                    "\n        "
                )
              ])
            ])
          : _vm._e(),
        _vm.cart.taxes.breakdown && _vm.cart.taxes.breakdown.pst
          ? _c("tr", { attrs: { row: "", "justify-space-between": "" } }, [
              _c("td", [_vm._v("\n          PST/QST\n        ")]),
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm._f("currency")(_vm.cart.taxes.breakdown.pst)) +
                    "\n        "
                )
              ])
            ])
          : _vm._e(),
        _c("tr", { attrs: { row: "", "justify-space-between": "" } }, [
          _c("td", [_vm._v("\n          Sales Taxes\n        ")]),
          _vm.cart && _vm.cart.taxes
            ? _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm._f("currency")(_vm.cart.taxes.amount)) +
                    "\n        "
                )
              ])
            : _vm._e()
        ]),
        _vm.cart && _vm.cart.discount && _vm.cart.discount.amount > 0
          ? _c("tr", { attrs: { row: "", "justify-space-between": "" } }, [
              _c("td", [_vm._v("\n          Discount\n        ")]),
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s("$" + this.getDecimal(_vm.cart.discount.amount)) +
                    "\n        "
                )
              ])
            ])
          : _vm._e(),
        _vm.cart && _vm.cart.promo && _vm.cart.promo.amount > 0
          ? _c("tr", { attrs: { row: "", "justify-space-between": "" } }, [
              _c("td", [_vm._v("\n          Promotion\n        ")]),
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s("$" + this.getDecimal(_vm.cart.promo.amount)) +
                    "\n        "
                )
              ])
            ])
          : _vm._e(),
        _vm.cart && _vm.cart.service_fee && _vm.cart.service_fee.amount > 0
          ? _c("tr", { attrs: { row: "", "justify-space-between": "" } }, [
              _c("td", [_vm._v("\n          Service Fee\n        ")]),
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s("$" + this.getDecimal(_vm.cart.service_fee.amount)) +
                    "\n        "
                )
              ])
            ])
          : _vm._e(),
        _vm.cart && _vm.cart.delivery_fee && _vm.cart.delivery_fee.amount > 0
          ? _c("tr", { attrs: { row: "", "justify-space-between": "" } }, [
              _c("td", [_vm._v("\n          Delivery Fee\n        ")]),
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      "$" + this.getDecimal(_vm.cart.delivery_fee.amount)
                    ) +
                    "\n        "
                )
              ])
            ])
          : _vm._e(),
        _c("tr", { attrs: { row: "", "justify-space-between": "" } }, [
          _c("td", [_vm._v("\n          Total\n        ")]),
          _vm.cart && _vm.cart.total
            ? _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm._f("currency")(_vm.cart.total.amount)) +
                    "\n        "
                )
              ])
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }